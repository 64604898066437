import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import { HTMLContent } from "../components/Content";
import styled from "styled-components";

const OuterPadding = styled.div`
  margin-left: 40px;
  margin-right: 40px;
  .iframeContainer {
    display: flex;
    justify-content: center;
    iframe {
      flex: 1;
      height: 50vh;
      min-height: 400px;
    }
  }
  * {
    font-family: "Noto Serif JP", sans-serif, -apple-system, BlinkMacSystemFont,
      "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans",
      "Helvetica Neue", serif;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: "EB Garamond", serif, -apple-system, BlinkMacSystemFont,
      "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans",
      "Helvetica Neue", sans-serif !important;
  }
`;

export const FindTemplate = ({ html, iframe, iframebefore }) => {
  return (
    <Layout>
      <OuterPadding>
        {!!iframebefore && (
          <div className="iframeContainer">
            <iframe src={iframe}></iframe>
          </div>
        )}
        <HTMLContent content={html} />
        {!iframebefore && (
          <div className="iframeContainer">
            <iframe src={iframe}></iframe>
          </div>
        )}
      </OuterPadding>
    </Layout>
  );
};

export const Find = (data) => {
  const {
    data: {
      markdownRemark: {
        html,
        frontmatter: { iframe, iframebefore },
      },
    },
  } = data;
  return (
    <FindTemplate html={html} iframe={iframe} iframebefore={iframebefore} />
  );
};

export const pageQuery = graphql`
  query FindQuery($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        iframe
        iframebefore
      }
    }
  }
`;

export default Find;
